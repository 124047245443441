.select_container {
  display: grid;
  gap: 1px;
  @media only screen and (min-width: 600px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media only screen and (max-width: 600px) {
    grid-template-columns: repeat(2, 1fr);
  }
  align-items: center;
  justify-items: center;
}

.option {
  padding: 3px 6px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.option.active {
  border: 2px solid rgb(28, 105, 212);
  padding: 1px;
}

.option .option_badge {
  position: absolute;
  top: -5px;
  left: -5px;
  background-color: rgb(28, 105, 212);
  color: white;
  border-radius: 50%;
  padding: 0px 2px;
  font-size: 14px;
}

.option_image {
  object-fit: cover;
  @media only screen and (min-width: 600px) {
    width: 108px;
    height: 76px;
  }
  @media only screen and (max-width: 600px) {
    width: 80px;
    height: 45px;
  }
  border-radius: 0.2rem;
}
