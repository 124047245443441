.wheels_accordion{
  margin-top: 0.25rem;
  padding: 0.5rem 1.4rem;
  @media only screen and (max-width: 600px) {
    padding: 0.3rem 1rem;
    font-size: 0.9rem;
  }
}

.wheels_accordion header {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

.wheels_accordion header h4 {
  margin: 0;
}

.wheels_accordion header .icon {
  color: #262626;
}

.wheels_accordion_content {
  transition: max-height 0.3s ease;
  overflow: hidden;
}

.wheels_accordion_content .select_div {
  padding-top: 0.5rem;
  padding-bottom: 0.25rem;
  display: flex;
  justify-content: space-between;
  @media only screen and (max-width: 600px) {
    padding-bottom: 0rem;
  }
}

.wheels_accordion_content .select_div label {
  margin-left: 8px;
  padding-right: 0.7rem;
}