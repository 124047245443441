.edit_car {
  position: relative;
  background-color: white;
  border-radius: 0.2rem;
}

.edit_car h2 {
  margin: 0;
  padding: 0.6rem;
  padding-left: 1.4rem;


  @media only screen and (max-width: 600px) {
    font-size: 1.25rem;
    padding: 0.5rem;
    padding-left: 1rem;
  }
}

.edit_car hr {
  margin: 0;
  height: 1px;
  background-color: #CCC;
  border: none;
}

.edit_car .inputs {
}
