body, button {
  margin: 0;
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  color: #262626;
  font-style: normal;
  font-variation-settings: "slnt" 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1,
h2,
h3,
h4 {
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-weight: 300;
  font-style: normal;
  font-variation-settings: "slnt" 0;
  color: #262626;
}

button {
  cursor: pointer;
  border-radius: 0.2rem;
  border: none;
}

button:active {
  transform: translateY(0.1rem);
}