.canvas {
  position: absolute;
}

.canvas-wrapper {
  position: relative;
  flex-grow: 1;
}

.edit-car-menu{
  position: absolute;
  top: 1rem;
  left: 1rem;
}

.buttons-wrapper {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  padding: 0.25rem;
  display: flex;
  gap: 0.5rem;
  border-radius: 0.2rem;
  background-color: white;
  bottom: 1rem;
}

.share-button {
  position: absolute;
  top: 1rem;
  right: 1rem;
  z-index: 1;
}
