.button {
  padding: 0.65rem 1.35rem;
  font-weight: 600;
}

.button.primary {
  background-color: white;
}

.button.secondary {
  color: white;
  background-color: rgb(28, 105, 212);
}
