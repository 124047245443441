.overlay-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Fond semi-transparent pour l'ombre */
  z-index: 1; /* Assure que l'overlay soit au-dessus de tout le reste */
}

.overlay-content {
  position: relative;
  background-color: white;
  padding: 2rem;
  padding-top: 1rem;
  border-radius: 0.2rem;
  z-index: 1; /* Assure que le contenu soit au-dessus de l'ombre */
}