.share-infos {
  text-align: center;
}

.share_button{
  border-radius: 50rem;
  padding: 1.25rem;

  @media only screen and (max-width: 600px) {
    padding: 1.125rem;
  }
}
