.windows_accordion{
  margin-top: 0.25rem;
  padding: 0.5rem 1.4rem;
  padding-top: 1rem;
  @media only screen and (max-width: 600px) {
    padding: 0.3rem 1rem;
    font-size: 0.9rem;
  }
}


.windows_accordion header {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

.windows_accordion header h4 {
  margin: 0;
}

.windows_accordion header .icon {
  color: #262626;
  transition: all 300ms linear;
}

.windows_accordion header .icon.rotate {
  transform:rotate(180deg);
}

.windows_accordion_content {
  transition: max-height 0.3s ease;
  overflow: hidden;
}

.windows_accordion_content .checkbox-div {
  padding-top: 0.5rem;
  padding-bottom: 0.25rem;
  display: flex;
  align-items: center;
  @media only screen and (max-width: 600px) {
    padding-bottom: 0rem;
  }
}

.windows_accordion_content .checkbox-div label {
  margin-left: 8px;
  @media only screen and (max-width: 600px) {
    font-size: 0.85rem;
  }
}
