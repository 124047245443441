.App {
  height: 100vh;
  width: 100vw;
  display: flex;
}

button {
  cursor: pointer;
}

.menu-button {
  position: absolute;
  top: 1rem;
  left: 1rem;
  background: none;
  border: none;
  z-index: 1;
}

.menu-button button {
  background: none;
  border: none;
  color: white;
}
