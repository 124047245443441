

.button_for_svg{
  width: 2.5rem;
  height: 2.5rem;
  border: 0;
  padding: 0;
  position: relative;
  display: inline-block;
  background-color: white;
}

.button_for_svg.active{
  background-color: rgb(28, 105, 212);
}

.button_for_svg img {
  width: 1.75rem;
  height: 1.75rem;
  background-size: cover;
}

.button_for_svg .tooltip{
  padding: 0.4rem;
  border-radius: 0.2rem;
  position: absolute;
  top: -36px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgb(38, 38, 38);
  color: white;
  width: max-content;
}

